.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 112.0rem;
  margin: 0 auto;
}

.App-link {
  color: #61dafb;
}

.container {
  min-height: 400px;
  color: white;
}

.word-image-solved {
  box-shadow: inset 0px 0px 0px 10px green;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #282c34;
}

.reply-letter a {
    float: right;
    margin-top: -10px;
    font-size: 30px;
}

.user-total-score {
    font-size:30px;
}

#reply-buttons .button-small {
    font-size:1em;
    margin:0;
    padding:0.1em;
    height:2em;
    width:2em;
}
#reply-buttons table td{
    margin:0;
    padding:0;
    /*display: inline-block;*/
    word-wrap: break-word;
    border-bottom: 0;
}

#reply-buttons td span {
    font-size:40px;
    margin:0;
    padding:0.1em;
    height:4em;
    width:4em;
}

select {
    color: white;
}

.image-selection-letters {
    font-size: 55px;
}
